@import '~@angular/material/theming';
@include mat-core();    
$my-app-primary: mat-palette($mat-grey);
$my-app-accent:  mat-palette($mat-pink, 500, 900, A100);
$my-app-warn:    mat-palette($mat-deep-orange);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);



.alternate-theme {
    $alternate-primary: mat-palette($mat-light-blue);
    $alternate-accent:  mat-palette($mat-yellow, 400);
    $alternate-theme: mat-light-theme($alternate-primary, $alternate-accent);
    @include angular-material-theme($alternate-theme);
  }
.login_card  {
    // line-height: 0% !important;
    // margin-top:0.8125em !important;
    
}
button:focus {
    outline: none;
    // background-color: inherit !important;
}
// .mat-toolbar{
//      font: none !important; 
//     margin: 0;
// }




bh-home :focus {
    outline-color: transparent !important;
}


// .carousel button:focus {
//     background-color: transparent !important;
// }

bh-benefits .mat-expansion-indicator::after{
    color: white !important;
}
 bh-login .mat-form-field-appearance-legacy .mat-form-field-label{
  color:#cccccc !important;
}
 bh-login .mat-form-field-appearance-legacy .mat-form-field-underline{
  background-color:#cccccc !important;
}
 bh-getquote .mat-form-field-appearance-legacy .mat-form-field-label{
  color:#cccccc !important;
}
 bh-getquote .mat-form-field-appearance-legacy .mat-form-field-has-label{
  color:#cccccc !important;
}
 bh-getquote .mat-form-field-appearance-legacy .mat-form-field-underline{
  background-color:#cccccc !important;
}

.button-no-highlight button:focus{
    background-color: transparent !important;
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
    background-color: transparent !important;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: transparent !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    width: 100%;
}

.mat-form-field-infix{
    width: 100%
}

.mat-drawer-backdrop {
    height: 100vh;
}

.mat-drawer-container-mobile{
    // position: unset !important;
    overflow: visible;
    
}




tr {
 border-bottom: 1px solid transparent !important; 
}

.mat-select-value{
    padding-bottom: 0.4em;
}
.mat-chip-list-wrapper{
    margin:unset !important
}
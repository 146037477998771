/*Importing default angular material css theme. */

@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import "nDefaults.scss";

// @import 'css-star-rating/scss/star-rating';
:root {
    --rusty-red: #c5281c;
    --battleship-grey: #6f7072;
    --almost-black: #090e15;
    --black: #000000;
    --smoke-white: #f6f6f6;
    --slate-grey: #6e6f71;
    --white: #ffffff;
    --almost-black1: rgba(9, 14, 21, 0.9);
    --light-grey: #b0b2b3;
    --light-grey-blue: #b0b2b3;
    --brown-grey: #9b9b9b;
    --battleship-grey: #6f7072;
    --very-light-pink: #efefef;
    --dark-grey-text: #6e6f71;
    --font-family: Roboto
}
.hr-not-selected{
    border-bottom: 1px solid #C5281C;
    width: 100%;
    transform: rotate(180deg);
}
.hr-selected{
    border-bottom: 1px solid #ffffff;
    width: 100%;
    transform: rotate(180deg);   
}

 .new-enhanced-selected{
    color: red;
    border-radius: 30px;
    position: relative;
    top: 8.5px;
    left: 15px;
    font-size: 11px;
    background-color: #f6f6f6;
    padding-left:2px;
    padding-right:2px;

}
.towTruckDetails{
    padding-left: 16px;
    color: rgba(111, 112, 114, 1);
    font-weight:700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
}
.roadsideStatus{
    background-color: rgba(194, 27, 23, 1);
    padding: 4px 10px;
    border-radius: 6px;
    color:rgba(255, 255, 255, 1);
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.50px;
    font-size: 12px;

}
.driverName{
    padding: 4px 12px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.25px;
    font-size: 16px;
    

}
.truckNumber{
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-size: 14px;
    color: rgba(111, 112, 114, 1);
    padding:1px 0px;

}
.truckStatus{
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    margin:16px;
    border-radius: 4px;
    word-break: break-word;
    line-height:16px;
    padding:16px;
    font-size: 15px;
    color: #090e15;
    text-align: center;
}

.new-enhanced-deselected{
    color: black;
    border-radius: 30px;
    position: relative;
    top: 8.55px;
    left: 1rem;
    font-size: 11px;
    background-color: #f6f6f6;
    padding-left:2px;
    padding-right:2px;

}

 .new-enhanced-selected-mobile{
    color: red;
    border-radius: 3px;
    position: relative;
    left: 35px;
    font-size: 11px;
    background-color: #f6f6f6;
    padding: 2px 5px;

}
.new-enhanced-deselected-mobile{
    color: black;
    border-radius: 3px;
    position: relative;
    left: 35px;
    font-size: 11px;
    background-color: #f6f6f6;
    padding: 2px 5px;

}

.margin-3 {
    margin: 0px 3% 3% 0px !important;
}

.bullet {
    height: 6px;
    width: 6px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
}

.cirle-align {
    width: 2%;
    margin: 6px;
}

.italicclass {
    //   font-style: italic;
    background: #ebebeb;
}

.selectedRowHighlight {
    background: #ebebeb;
}

.omni-text-color {
    color: #c5281c;
}

.myagent-bckimg {
    background-image: url("assets/Web/Bitmap.png");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

@font-face {
    font-family: Roboto;
}

.image-row {
    margin-top: 5%;
    margin-bottom: 5%;
}

.margin-bottom {
    .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #ffffff !important;
    }
    margin-bottom: 1.3em;
}

.margin-top1 {
    margin-top: 2em;
}

.padding-tb1 {
    padding-top: 1em;
    padding-bottom: 1em;
}

.login-column {
    padding-top: 10%;
    padding-bottom: 10%;
}

.margin-login-grid {
    margin-top: -72px;
}

.rectangle-sm {
    min-width: 20em;
}

.rectangle-md {
    width: 15em;
}

.rectangle-lg {
    width: 22em;
}

.rectangle-xs {
    min-width: 100%;
}

.header_fields {
    text-align: center;
    font-size: 17px;
}

.header_web_fields {
    // width:5.75em;
    // height: 1.375em;
    font-size: 1em !important;
    font: Roboto;
    cursor: pointer;
}

.sidenav_content_matrgin {
    margin-top: 4em;
}

.header_web_fixed {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
}

.hr {
    position: relative;
    top: 20px;
    border: none;
    height: 12px;
    background: rgb(143, 15, 15);
    margin-bottom: 50px;
}


/* Safari 10.1+ (alternate method) */

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .header_web_fixed {
            position: relative;
        }

        .sidenav_content_matrgin {
            margin-top: 0em;
        }

        .marginto_4 {
            margin-top: 4% !important;
        }

        .marginto_6 {
            margin-top: 6% !important;
        }

        .marginto_2 {
            margin-top: 2%;
        }
    }
}

.card_web {
    background-color: #ffffff;
    margin-top: 76px;
    height: 85%;
}

.web_grid {
    background-color: #ffffff;
    margin-top: 90px;
    margin-bottom: 65px;
}

.scroll-imp {
    overflow-y: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch !important;
    -webkit-transform: translateZ(0px);
}

.reg-web {
    margin-top: 4px !important;
}

.notification_dele {
    background-color: #c5281c !important;
    color: #ffffff !important;
    width: 136px;
}

.notification_cancel {
    border: 1px solid var(--slate-grey) !important;
    width: 150px;
    color: var(--slate-grey) !important;
}

.image--cover {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center right;
}

.image--cover--web {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center right;
}

.image--cover-home {
    width: 1.875em;
    height: 1.875em;
    border-radius: 50%;
    object-fit: cover;
    //   object-position: center right;
}

.image--cover-login {
    width: 4em;
    height: 4em;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    //   object-position: center right;
}

.profile-field {
    // margin-left: 5%;
}

.my-agent-sub-rows {
    padding: 1em 1em 0.4em 1em;
}

.prf-color {
    color: var(--almost-black);
    font-size: 17px;
}

.sidenav-prf {
    color: #ffffff !important;
    font-size: 17px;
}

.font-24 {
    font-size: 24px;
}

.sidenav-mo {
    position: inherit !important;
    width: auto !important;
    background-color: var(--rusty-red) !important;
    color: #ffffff !important;
    margin-left: 2.125em;
}

.mobile-sidenav {
    width: 80%;
    background-color: var(--rusty-red) !important;
}

.navbar-web {
    color: #ffffff !important;
    background-color: transparent !important;
}

.navbar-web-red {
    color: #ffffff !important;
    background-image: linear-gradient(282deg, var(--rusty-red), #e4513c) !important;
}

.setting-field {
    color: var(--slate-grey);
    font-size: 14px;
}

.setting-label {
    font-size: 17px;
    color: var(--almost-black);
}

.claims-label {
    color: var(--almost-black1);
    font-size: 22px;
}

.claims-labeltracking {
    color: var(--almost-black1);
    font-size: 17px;
}

.claims-paragraph {
    color: var(--brown-grey);
    font-size: 14px;
}

.claims-btn {
    color: #ffffff;
    background-color: #7ed321 !important;
    border-radius: 12px !important;
    width: 87px;
    height: 24px;
}

.claims-card {
    border-radius: 8px;
    margin: 20px;
    // min-height: 230px;
}

.claimstracking-p {
    color: var(--slate-grey);
    font-size: 14px;
}

.claimstracking_p_span {
    color: var(--almost-black);
    font-size: 14px;
}

.claimtracking_exppanel {
    font-size: 17px;
    color: var(--almost-black);
}

.claim_heading {
    background-color: #e8e8e8;
    height: 3em;
}

.notification-badge {
    background-color: #e8e8e8;
    border-radius: 12px;
    color: #c5281c;
    height: 20px;
    width: 2.5rem;
    padding: 2px;
    text-align: center;
}

.agent-btn {
    color: #ffffff !important;
    height: 34px;
    background-color: #7ed321 !important;
    border-radius: 40px !important;
    transform: scale(0.7);
    font-size: 22px !important;
}

.agent-circle {
    height: 2.75em;
    width: 2.75em;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
}

.agentImg-circle {
    height: 88px;
    width: 88px;
    border-radius: 50%;
    display: inline-block;
}

.agent-img {
    height: 1.5em;
    width: 1.5em;
    margin: 9px;
}

.agent-field {
    text-align: center;
    color: #ffffff !important;
    font-size: 1.625em;
}

.agent-par {
    text-align: center !important;
    color: #ffffff !important;
    font-size: 0.875em;
    // margin-left:0.25em;
}

.agent-maps agm-map {
    height: 18em;
}

.agent-prf {
    text-align: center;
    color: #ffffff !important;
    font-size: 14px;
}

.helpcenter-p-web {
    color: var(--almost-black);
    font-size: 19px;
    padding: 2%;
}

.agent-img2 {
    background-image: url("assets/SideMenuIcons/bg-s.png");
    background-position: center;
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-size: cover;
}

.addPolicy_field {
    color: var(--battleship-grey) !important;
    text-align: center;
    font-size: 17px;
}

.addPolicy_btn {
    height: 56px !important;
    background-color: #c5281c !important;
    color: #ffffff !important;
    margin: 0px !important;
    margin-top: 1px !important;
}

.policy_fields {
    color: #797a7c !important;
    text-align: center !important;
    font-size: 14px !important;
}

.policyfield_input {
    color: var(--slate-grey);
    font-size: 14px !important;
}

.marginlogin {
    margin: -7px !important;
}

.mypolicy-dialog {
    margin: -7px !important;
    text-align: center !important;
}

.form-input-fields {
    // width:100% !important;
}

// .mat-form-field {
//     display: inline-block;
//     position: relative;
//     text-align: left;
// }
@mixin clr-bgclr-font($color,
    $font-size,
    $font-weight,
    $background-color,
    $height) {
    color: $color !important;
    font-size: $font-size !important;
    font-weight: $font-weight;
    background-color: $background-color !important;
    height: $height;
}

.input-field {
    border-bottom: none !important;
}

// .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
//     font-size: 14px !important;
//     color: #6e6f71 !important;
// }

.mapcentre {
    top: 298px !important;
    z-index: 0;
    position: absolute;
    right: 0px;
}

.mapinput {
    .mat-form-field-wrapper {
        padding-bottom: 6px !important;
    }

    .mat-form-field {
        .mat-input-element {
            font-size: 14px !important;
            color: #6e6f71 !important;
            padding-top: 3%;
        }
    }

    .mat-form-field-appearance-legacy .mat-form-field-label {
        padding-top: 6%;
        font-size: 14px !important;
        color: #6e6f71 !important;
    }
}

.panel-search {
    .mat-form-field-underline {
        bottom: 0px !important;
    }
}

.non-panel-search {
    .mat-form-field-underline {
        display: none !important;
    }
}

.omni-toolbar {
    background: #c5281c;
}

.popup_container>.mat-dialog-container {
    background-color: #c5281c;
}

.heading {
    background-color: var(--smoke-white);
    min-height: 3em;
}

agm-map {
    height: 75vh;
}

.maps agm-map {
    height: 14em;
}

a {
    color: darkblue;
}

body {
    font-family: Roboto !important;
    //   color: #FFFFFF
    // background: rgba(0, 0, 0, 0.249632);
    // background-image: url("assets/Web/Bitmap.png");
    // overflow-y: scroll;
}

html {
    font-family: Roboto !important;
}

.circle {
    height: 150px;
    width: 150px;
    background-color: #ffffff;
    border: 1px solid #c5281c;
    border-radius: 50%;
    display: inline-block;
}

.sidemenu {
    font-size: 19px;
    margin: 12px;
}

.home_toolbar {
    background: none !important;
    color: #ffffff !important;
}

.h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
}

bh-editpolicy_nmpi,
bh-helpbot {
    width: 100%;
}

bh-home,
bh-policyparticulars,
bh-tutorials,
bh-landing,
bh-registration,
bh-resetpassword,
bh-confirm_details,
bh-select_vehicle_maps,
bh-service,
bh-service_review,
bh-verifyotp,
bh-helpcenter,
bh-locatorfilter,
bh-myagent,
bh-getquote,
bh-locatorview,
bh-traveldialog,
bh-select_vehicle,
bh-motorsummary,
bh-motoraddon,
bh-motor_plans,
bh-motor_driver_details,
bh-motor_payment_status,
bh-motorgetquote {
    width: 100%;
}

bh-pdfdialog,
bh-policyparticulars,
bh-motor_driver_details,
bh-select_vehicle,
bh-settings,
bh-policydetails,
bh-policyfields,
bh-addpolicy,
bh-editprofile,
bh-helpcenter,
bh-login,
bh-login_step1,
bh-confirm_details,
bh-login_step2,
bh-auto_assit,
bh-select_service,
bh-header,
bh-myprofile,
bh-emergency_call,
bh-resetpassword,
bh-confirm_details_aa,
bh-upload_photos,
bh-wait_timer,
select_vehicle_maps,
bh-waitservice_ontheway_eta,
bh-auto_assistdialog,
bh-resetlink,
bh-verficationsuccess,
bh-verifyotp,
bh-forgotpassword,
bh-notifications,
bh-locatorview,
bh-myagent,
bh-myclaims,
bh-maintainance,
bh-offline,
bh-dialog,
bh-inbox,
service_review,
bh-pickup_dropoff_terms,
bh-workshoplocator,
bh-claimvehicle,
bh-bankdetails,
bh-lossdetails,
bh-claimlossdetails,
bh-claimsummary,
bh-select_vehicle_maps,
bh-claimdocuments,
bh-claimdetails,
bh-claimsparent {
    height: 100%;
    // width: 100%;
}

.dialogbox {
    display: block !important;
}

.mat-tab-body-wrapper {
    height: 100% !important;
}

.trip-option-right {
    text-align: right;
    place-content: center;
}

.trip-option-left {
    // text-align: left;
    place-content: center;
}

.beneficiary-title-right {
    text-align: right;
    font-size: 1.2em;
    place-content: center;
}

.beneficiary-title-left {
    text-align: left;
    place-content: center;
    font-size: 1.2em;
}

.beneficiary-sub-title-left {
    place-content: center;
    color: var(--battleship-grey);
    font-size: 12px;
    text-align: left;
}

.beneficiary-sub-title-right {
    place-content: center;
    color: var(--battleship-grey);
    font-size: 12px;
    text-align: right;
}

.beneficiary-summary-row {
    min-height: 30px;
    border-bottom: 0.5px solid var(--light-grey-blue);
    width: 90%;
    padding-bottom: 0.5em;
}

.trip-option-heading-text {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: var(--slate-grey);
}

.hint-grey-text {
    color: var(--battleship-grey);
}

.total-payable {
    font-family: Roboto;
    font-size: 33px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: 0.39px;
    color: #3d3d3d;
}

.trip-sum-left {
    color: white;
}

.trip-sum-right {
    color: white !important;
}

.mat-checkbox-layout {
    cursor: inherit;
    align-items: baseline;
    vertical-align: top !important;
    display: inline-flex;
    white-space: nowrap;
}

.motor-span .mat-checkbox-label {
    font-size: 14px !important;
    color: #333333;
}

.grey-icons {
    color: var(--battleship-grey) !important;
}

.red-icon {
    color: #c5281c !important;
}

.edit-row {
    padding: 1em;
}

//travel PA
bh-benefits,
bh-personalinfo,
bh-beneficiaries,
bh-trip_summary,
bh-paymentstatus,
bh-service_status {
    width: 100%;
}

bh-dialog {
    display: block !important;
}

.travel-bold {
    font-weight: 600;
}

.travel-field {
    width: 90%;
}

.travel-grey {
    background-color: var(--very-light-pink);
}

.personal-other-text {
    margin-left: 3.5%;
}

.travel-form {
    padding-top: 1em;
    padding-right: 1em;
    padding-left: 1em;
}

.contact-expansion {
    width: 100%;
}

.text-14 {
    font-size: 14px;
    color: var(--almost-black);
}

bh-benefits .mat-form-field {
    width: 100%;
}

.text-right {
    text-align: right;
}

.page-title-section {
    padding: 1em;
    padding-left: 1.8em;
    padding-right: 1.8em;
}

.page-title-section-big {
    font-size: 17px;
    color: var(--almost-black);
}

.page-title-section-small {
    font-size: 12px;
    color: #3c3c3c;
}

.expansion-head-txt {
    font-size: 14px !important;
    color: var(--white) !important;
}

.premium-first-row {
    background: #c5281c !important;
}

.padding-0 {
    padding: 0.5em;
}

.sub-section-heading {
    background-color: var(--smoke-white);
    color: var(--battleship-grey);
    padding: 1em;
}

.fixed-at-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.expanson-padding-right {
    padding: 0px 24px 0px 0px !important;
}

.mat-expansion-panel-body-travel {
    padding-top: 24px !important;
    padding-bottom: 20px !important;
}

.mat-expansion-panel-content {
    font: 400 14px/20px Roboto !important;
}

.mat-expansion-panel-header {
    font-family: Roboto !important;
}

.mat-tab-group {
    font-family: Roboto !important;
}

.mat-card {
    font-family: Roboto !important;
}

.mat-input-element {
    font-family: 'Roboto' !important;
}

input {
    font-family: Roboto !important;
}

.mat-bottom-sheet-container {
    font: 400 14px/20px Roboto !important;
}

.mat-radio-button {
    font-family: Roboto !important;
}

.mat-list-item {
    font-family: Roboto !important;
}

.mat-simple-snackbar {
    font-family: Roboto !important;
}

.mat-button {
    font-family: Roboto !important;
}

.mat-select {
    font-family: Roboto !important;
}

.mat-form-field {
    font-family: Roboto !important;
}

.mat-toolbar {
    font-family: Roboto !important;
}

.mat-tab-label {
    font-family: Roboto !important;
}

.mat-raised-button {
    font-family: Roboto !important;
}

.beneficiaries-slider {
    width: 90%;
}

.beneficiaries-box-shadow {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.beneficiaries-title {
    margin-left: 6%;
}

.toolbar-button {
    padding-left: 1.5%;
}

.toolbar-text {
    padding-left: 1.5%;
}

.slider-percentage-number {
    color: var(--rusty-red);
}

//travel PA end
.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    padding: 0;
}

// bh-select_vehicle{
//     height: -webkit-fill-available;
// }
.highlighted {
    background-color: grey;
}

.color-grey {
    color: #828282;
}

.font-12 {
    font-size: 12px !important;
}

.list {
    border-bottom: 1px solid black;
    display: block;
    height: auto;
    min-height: 99px !important;
    
} 


.Logo_White {
    width: 158px;
    height: 35px;
    object-fit: contain;
    margin-top: 10px;
}

.btn {
    //   width: 344px;
    height: 56px;
    background-color: #ffffff;
    font-size: 17px;
    font-weight: normal;
    color: #c5281c;
}

.whitebtn {
    background-color: #ffffff !important;
    font-size: 17px !important;
    font-weight: normal !important;
    color: var(--rusty-red) !important;
    height: 56px;
}

.btn_security {
    height: 2.5em !important;
    background-color: #acacac !important;
    font-size: 20px !important;
    font-weight: normal;
    color: #ffffff !important;
}

.resendotp {
    margin: 15px;
    color: var(--slate-grey);
}

.otp-verify-btn {
    width: 100%;
    bottom: 4em;
    padding: 1em;
}

.add-pol-btn {
    bottom: 1em;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}

.padding-lr1 {
    padding-left: 1em;
    padding-right: 1em;
}

.scrollable-element {
    scrollbar-color: red yellow;
    scrollbar-width: none;
}

.scrollable-element::-webkit-scrollbar {
    width: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollable-element::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #c5281c;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #b0b2b3 !important;
}

// .mat-form-field-appearance-legacy
.mat-form-field-suffix .mat-icon {
    width: 1em;
    color: #aeb0b1;
}

.mat-form-field-prefix {
    color: #6e6f71;
}

.panel-header-color1 .mat-expansion-panel-header {
    background-color: #c5281c !important;
    font-size: 18px;
}

.half-circle {
    width: 44px;
    height: 22px;
    background-color: var(--rusty-red);
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    color: white;
    box-sizing: border-box;
    text-align: center;
    position: absolute;
    bottom: 45px;
}

.sum-row {
    border-bottom: 1px solid #d17878;
    background-color: #d23a29 !important;
    padding: 16px;
}

.sum-column {
    background: #d13729;
}

.bottom-sum-toolbar-not-round {
    background-color: var(--rusty-red) !important;
    padding: 0 !important;
}

.bottom-sum-toolbar {
    background-color: var(--rusty-red) !important;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
}

.mat-bottom-sheet-container {
    padding: 0px !important;
}

.mat-expansion-panel-body {
    padding: 0px !important;
}

.mat-expansion-panel {
    border-radius: 0px !important;
}

.giant-expansion {
    height: 5em !important;
}

.mat-expansion-panel-spacing {
    margin: 0px !important;
}

.trans-list-description {
    height: 1em !important;
    font-size: 0.9rem !important;
    color: #000000 !important;
    font-weight: 600 !important;
}

.trans-list-item {
    font-size: 0.8rem !important;
    color: #707070 !important;
}

.trans-section {
    padding-bottom: 1.5em;
}

.trans-date {
    font-size: 12px !important;
}

.trans-head {
    font-size: 15px !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #c5281c !important;
}

.panel-description-bg .mat-expansion-panel-content {
    background-color: #a52e25 !important;
    // height: 12em;
    overflow-y: scroll;
}

.panel-indicator-bg .mat-expansion-indicator::after,
.mat-expansion-panel-header-description {
    color: #ffffff !important;
    //  font-size: #a71004 32px;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: green !important;
}

.expansion-panel-marin0 .mat-expansion-panel-spacing {
    margin: 0px !important;
}

.travel-progress-bar {
    padding: 1em;
    border-bottom: 1px solid #deddde;
}

.progress-step-text {
    color: var(--rusty-red);
    font-size: 14px;
    margin-top: 10px;
}

.reset {
    color: #5d5d5d;
    text-align: center;
    font-size: 1.0625rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    text-align: center;
    color: #5d5d5d;
}

.bottom-buttons {
    // font-size: 1.0625rem !important;
    height: 3.5em;
    background-color: #c5281c !important;
    color: #ffffff !important;
    // margin: 1em !important;
}

.photo-submit-button {
    font-size: 1.0625rem !important;
    height: 56px;
    background-color: #c5281c !important;
    color: #ffffff !important;
}

.resetlabel {
    color: var(--slate-grey) !important;
    font-size: 14px !important;
}

.loginimg {
    // background-image: url("assets/Web/Bitmap.png");
    background-repeat: no-repeat;
    background-size: cover;
    // background-color: red;
}

.travel-image {
    background-image: url("assets/travel/travel_plan_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
}

.travel-image-web {
    background-image: url("assets/travel/travel_plan_web.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    padding-left: 8em;
    padding-right: 8em;
    padding-top: 6em;
    padding-bottom: 4em;
}

.mat-checkbox-inner-container-no-side-margin {
    margin-left: 0;
    margin-right: none !important;
}

.mat-checkbox-frame {
    background-color: transparent;
    transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
    border-width: 1px !important;
    border-style: solid;
    border-color: #acacac !important;
}

.rs_slide_button {
    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
        background-color: #ffffff !important;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
        background-color: #cccccc !important;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
        transform: translate3d(26px, 0, 0) !important;
    }

    .mat-slide-toggle-bar {
        position: relative !important;
        width: 42px !important;
        height: 25px !important;
        flex-shrink: 0 !important;
        border-radius: 20px !important;
        // transform: scale(1.15);
    }

    .mat-slide-toggle-thumb {
        height: 16px !important;
        width: 17px !important;
        border-radius: 50% !important;
    }

    .mat-slide-toggle-thumb-container {
        position: absolute !important;
        z-index: 1 !important;
        width: 15px !important;
        height: 14px !important;
        top: 4px !important;
        left: 4px !important;
    }

    .mat-slide-toggle .mat-slide-toggle-ripple {
        position: unset !important;
        top: unset !important;
        left: unset !important;
        height: unset !important;
        width: unset !important;
        z-index: unset !important;
        pointer-events: unset !important;
    }

    .mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
        opacity: unset !important;
    }

    .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
        background-color: unset !important;
    }

    .mat-slide-toggle.mat-checked .mat-ripple-element {
        background-color: unset !important;
    }
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: #cccccc !important;
}

.mat-form-field-underline .mat-form-field-ripple {
    background-color: #cccccc !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #ffffff !important;
    // margin: 3px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #4cd964 !important;
    // height: 22px !important;
}

// .mat-slide-toggle-label-before .mat-slide-toggle-thumb{
//     margin: 3px !important;
// }
// .mat-slide-toggle-label-before .mat-slide-toggle-bar{
//     height: 22px !important;
// }
.touch-toggle {
    margin-top: 5px !important;
}

// .mat-slide-toggle-bar {
//     position: relative;
//     width: 36px;
//     height: 22px !important;
//     flex-shrink: 0;
//     border-radius: 8px;
// }
// .mat-slide-toggle-bar {
//     background-color: rgba(208, 34, 34, 0.38);
//     height:22px !important;
// }
// .mat-slide-toggle .mat-accent .mat-slide-toggle-label-before{
// }
// mat-slide-toggle-label-before
.node-circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 1px solid var(--light-grey-blue) !important;
    margin: 8px 4px 0px 4px;
}

.node-circle-red {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    transition: all 1000ms ease;
    border: 1px solid var(--rusty-red) !important;
    background-color: var(--rusty-red);
    margin: 8px 4px 0px 4px;
}

.divider-dots {
    margin-top: -5px;
    border-left: 3px dotted;
    color: var(--light-grey-blue) !important;
    margin-bottom: -8px;
}

// li p {
//     display:inline-block;
//     margin-left: 25px;
// }
// li {
//     list-style: none;
//     line-height: 1px;
// }
.blue {
    background-color: rgba(82, 165, 255, 1);
}

.green {
    background-color: rgba(92, 184, 92, 1);
}

.red {
    background-color: rgba(255, 148, 148, 1);
}

.grey {
    background-color: rgba(201, 201, 201, 1);
}

// .star-rating{
.to-rate {
    cursor: pointer;
    padding: 0 3px;
}

.fa-star-o {
    color: orange;
}

.fa-star {
    color: orange;
}

.fa-star-half-o {
    color: orange;
}

.to-display {
    padding: 0 2px;
}

// x
// mat-form-field {
//     display: inline-block;
//     position: relative;
//     text-align: left;
//     margin-top: -18px;
// }
// .divider{
//     color:#FFFFFF;
// }
// .Rectangle {
//     position: absolute;
// height: 714px;
// left: 0px;
// right: 0px;
// top: 0px;
// // background: rgba(0, 0, 0, 0.249632);
// }
.img_btn .mat-button cdk-focused cdk-touch-focused {
    background-color: none;
}

// $num-of-char: 4;
// $char-width: 2ch;
// $gap: $char-width;
// $in-w: ($num-of-char * ($char-width + ($gap))) ;
// .otp input {
// 		display: block;
// 	border: none;
// 	width: $in-w;
// 	background: repeating-linear-gradient(90deg,
// 		#000 0, #000 $char-width,
// 		transparent 0, transparent $char-width + $gap)
// 		0 100%/ #{$in-w - $gap} 2px no-repeat;
// 	font: 4ch droid sans mono, consolas, monospace;
// 	letter-spacing: 1.5*$gap;
// 	&:focus {
// 		outline: none;
// 	}
// }
.icon-buttons-nav {
    background: transparent !important;
}

.carList {
    font-size: 14px !important;
    color: var(--slate-grey);
    word-wrap: break-word;
    width: 95%;
}

.carnum {
    color: rgba(9, 14, 21, 0.9) !important;
    // font-weight:bold;
    font-size: 17px !important;
}

.service {
    color: var(--almost-black);
    font-size: 17px !important;
}

// .star-rating{
.to-rate {
    cursor: pointer;
    padding: 0 3px;
}

.fa-star-o {
    color: orange;
}

.fa-star {
    color: orange;
}

.fa-star-half-o {
    color: orange;
}

.to-display {
    padding: 0 2px;
}

.rating {
    float: left;
}

.rating:not(:checked)>input {
    position: absolute;
    top: -9999px;
    clip: rect(0, 0, 0, 0);
}

.rating:not(:checked)>label {
    float: right;
    width: 1em;
    padding: 0.1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 200%;
    line-height: 1.2;
    color: #ddd;
}

.rating:not(:checked)>label:before {
    content: "★ ";
}

.rating>input:checked~label {
    color: #f70;
}

.rating:not(:checked)>label:hover,
.rating:not(:checked)>label:hover~label {
    color: gold;
}

.rating>input:checked~label:hover,
.rating>input:checked~label:hover~label,
.rating>label:hover~input:checked~label {
    color: #ea0;
}

.hint-errors .mat-hint {
    color: #ff5722 !important;
}

//rubbish attempt
input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
    border-bottom: none !important;
}

//     input[type-email]:not(.browser-
// default): focus not([readonly]) {
//     box-shadow:none !important;
// }
input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 0px;
    width: 100%;
    font-size: 16px;
    margin: 0 0 8px 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s, border 0.3s;
    transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
}

button:focus {
    outline: inherit;
}


/* Text inputs */

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
    height: 1.6rem !important;
    margin: 0px !important;
}

//Home page css - Start
.title-main {
    font-size: 2.1em;
    letter-spacing: 1.4px;
    color: white;
}

.title-sub {
    letter-spacing: 0.2px;
    font-size: 13px;
    color: white;
    padding-bottom: 0.2em;
}

.width100 {
    width: 100% !important;
}

.width-100 {
    width: 100%;
}

.margin-center {
    margin: 0 auto !important;
}

.red-col {
    height: 5em;
    position: relative;
    top: -2.5em;
    border-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(282deg, var(--rusty-red), #e4513c);
}

.main-tile {
    background-image: url("assets/mobile/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.red-banner-text {
    color: white;
    font-size: 0.65em;
    padding-top: 0.5em;
}

.grey-bg {
    background: #f2f2f2;
    padding: 1em;
}

.sub-title {
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 0.4px;
    color: #5d5d5d;
}

.upshift {
    top: -0.8em;
    position: relative;
}

.policy-icon {
    height: 2.4em;
}

.policy-close {
    height: 1em;
    color: #6e6f71;
}

.policy_title1 {
    font-size: 0.8em;
    color: #6e6f71;
    line-height: 1.42;
    letter-spacing: 1px;
}

.policy_title2 {
    font-size: 1.2em;
    color: #080e16;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    padding-bottom: 0.5em;
}

.policy_policyno {
    padding-top: 0.5em;
    font-size: 0.9em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    color: #6e6f71;
}

.policy_address {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    color: #6e6f71;
    padding-top: 0.4em;
    margin-bottom: -0.8em;
}

.policy_btn {
    padding: 0.56em 1.75em 0.2em 1.75em;
    border-radius: 25px;
    font-size: 0.85em;
}

.policy_card {
    background: white;
    border-radius: 12px;
    margin-bottom: 1em;
    padding-top: 1em;
    // padding-bottom: 1em;;
}

.policy_payment {
    background-color: rgba(241, 100, 74, 0.1);
    color: coral;
    padding: 1em 0em 0.8em 0.6em;
    margin-top: 0.8em;
}

.policy_web_cards {
    padding-bottom: 1em;
    padding-right: 1em;
    padding-left: 1em;
}

.pay_now {
    color: white;
    background: #c5281c;
    padding-top: 1em;
    border-radius: 0 0 12px 12px;
    padding-bottom: 1em;
    margin-top: 0.8em;
}

.pay_now1 {
    color: white;
    background: #c5281c;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-top: 0.8em;
}

.padding-l5 {
    padding-left: 0.5em;
    margin-top: 0.8em;
}

.padding-tb {
    padding-top: 1em;
    padding-bottom: 1em;
}

.notifications-row {
    padding-top: 1em;
    padding-bottom: 1em;
    width: 100% !important;
    border-bottom: 1px solid #d8d8d8;
}

.notifications-title1 {
    font-size: 0.8em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.22px;
    color: rgba(21, 24, 26, 0.6);
}

.noti-title1-row {
    padding-top: 0.3em;
    padding-bottom: 0.2em;
}

.notifications-title2 {
    padding-top: 0.1em;
    padding-bottom: 0.5em;
    color: #090e15;
}

.notifications-date {
    color: #6e6f71;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
}

.notifications-title3 {
    white-space: nowrap;
    width: 15em;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    color: var(--slate-grey);
}

.noti-arrow {
    color: #6f7072 !important;
}

.last {
    border-bottom: 1px solid white !important;
}

.see-all {
    padding-left: 0.6em;
}

.see-all-text {
    padding-top: 0.3em;
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.5px;
    color: var(--slate-grey);
    cursor: pointer;
}

.add-card {
    min-width: 90% !important;
    max-width: none !important;
    background: white;
    padding: 1.2em;
    border-radius: 12px;
    margin-right: 1em;
}

.padding-t1 {
    padding-top: 1em;
}

.add-btn {
    background: #7ed321;
    color: white;
    padding: 0.5em 1em 0.2em 1em;
    border-radius: 25px;
}

.add-btn-text {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.34px;
    text-align: center;
    color: var(--white);
}

.add-title1 {
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.5px;
    color: var(--almost-black);
    padding-top: 1em;
}

.add-title2 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    color: #808080;
    padding-top: 0.8em;
}

.padding-t5e {
    padding-top: 5em !important;
}

.news-card {
    // min-width: 90%;
    min-width: calc(100vw - 5em);
    max-width: none !important;
    background: white;
    border-radius: 12px;
    margin-right: 1em;
}

.news-card-web {
    background: white;
    border-radius: 12px;
}

.news-date {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.34px;
    text-align: right;
    color: var(--slate-grey);
}

bh-policies {
    height: 100%;
}

.padding1em {
    padding: 1em;
}

.padding2em {
    padding: 2em;
}

.height100vh {
    height: 100vh !important;
}

.height100 {
    height: 100%;
}

.col-addpolicies {
    margin-top: -10em;
    padding: 2em;
}

.news-title {
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: rgba(9, 14, 21, 0.9);
    padding-top: 1em;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
}

.news-icon {
    color: var(--rusty-red);
}

.padding-t5 {
    padding-top: 0.5em;
}

.percentages-box {
    padding-left: 3.5%;
    height: 3em;
}

.text-center {
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    text-align: center;
    color: #feffff;
    padding: 1.5em 0;
}

.red-bigbg {
    background-image: linear-gradient(313deg, var(--rusty-red), #e4513c);
    padding-right: 1.5em;
    padding-left: 1.5em;
    padding-bottom: 3.5em;
}

.add2icon {
    height: 1.5em;
}

.add2icontxt {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83;
    letter-spacing: 0.14px;
    color: aliceblue;
    padding-left: 1em;
}

.add2-card {
    min-width: 90% !important;
    max-width: none !important;
    padding: 1.2em;
    border-radius: 12px;
    margin-right: 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 22em;
}

.add2-title {
    padding-top: 5em;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    color: rgba(255, 255, 255, 0.9);
}

.add2-title-web {
    // padding-top: 5em;
    font-size: 1.6em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    color: rgba(255, 255, 255, 0.9);
}

.add2-title2 {
    padding-top: 0.8em;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.16px;
    color: rgba(255, 255, 255, 0.9);
}

.web-banner {
    // background-image: url("assets/lllustration/web_banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // padding-top: 3em;
    padding-bottom: 1em;
}

.padding-l9 {
    padding-left: 7em;
}

.padding-lr1 {
    padding-left: 1em;
    padding-right: 1em;
}

.vehicle-details-text {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    color: var(--slate-grey);
    padding-top: 1em;
}

.height-3 {
    height: 3.5em;
}

.web-crum-tex {
    padding-left: 0.5em;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.94;
    letter-spacing: 0.49px;
    color: var(--white);
    padding-top: 0.4em;
}

.web-agent-title1 {
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.7;
    letter-spacing: 1px;
    color: var(--white);
}

.web-agent-title2 {
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.94;
    letter-spacing: 0.49px;
    color: var(--white);
    padding-top: 0.2em;
}

.web-agent-arrow {
    color: white;
}

.web-agent-section {
    padding-top: 0.8em;
    padding-left: 1em;
}

.margin-t-1 {
    margin-top: -1em;
}

.padding-btm2em {
    padding-bottom: 3em;
}

.footer-bg {
    background: #8a8a8a;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.sos-image {
    padding-top: 2em;
    padding-bottom: 1em;
    margin: 0 auto;
}

.sos-number {
    margin: 0 auto;
    font-family: Roboto;
    font-size: 33px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--rusty-red);
}

.sos-title {
    margin: 0 auto;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: 0.2px;
    text-align: center;
    color: var(--battleship-grey);
}

.footer-logo {
    height: 3em;
}

.footer-logo-row {
    padding-top: 1em;
    padding-bottom: 3em;
}

.footer-text {
    padding-bottom: 2em;
    color: white;
    font-size: 0.89em;
    cursor: pointer;
}

.social-icons {
    padding-right: 1em;
    height: 3em;
    cursor: pointer;
}

.text-white {
    color: white;
}

.padding-b5 {
    padding-bottom: 0.5em;
}

// .container{
//     // width: 1032px !important;
//     margin: 0 auto;
// }
// .containerOmni{
//     margin : 0 auto;
// }
.web-add-text {
    padding-top: 1.5em;
    color: white;
    font-size: 26px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.85;
    letter-spacing: 0.31px;
    text-align: center;
    color: #feffff;
    padding-bottom: 0.5em;
}

.web-add-text-2 {
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    text-align: center;
    color: #feffff;
    padding-bottom: 1em;
}

.footer-followus {
    padding-bottom: 1em;
    font-size: 0.89em;
}

.footer-download {
    color: white;
    font-size: 0.89em;
    padding-bottom: 1em;
}

.padding-b1 {
    padding-bottom: 0.8em;
}

.padding-l1 {
    padding-left: 1em;
}

.padding-right {
    padding-right: 1em;
}

.footer-btm-text {
    font-size: 0.8em;
    padding-top: 0.2em;
}

.btm-footer {
    background: #757474;
    padding: 1em 1.5em;
}

.padding-l1em {
    padding-left: 1em;
}

.font1em {
    font-size: 1em !important;
}

.paddingl05 {
    padding-left: 0.5em;
}

.paddingt05 {
    padding-top: 0.5em;
}

.add2icon-web {
    height: 2em;
}

.pol-icon {
    height: 28px;
}

.add2-web-row1-p {
    padding-left: 0.5em;
    padding-top: 0.5em;
}

.paddingt02 {
    padding-top: 0.4em;
}

.maxWidth {
    max-width: 5.313em !important;
}

.width10 {
    width: 10em;
}

.no-paddintb05 {
    padding-bottom: 0 !important;
}

.noti-value {
    display: block;
    height: 30px;
    width: 30px;
    line-height: 35px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: #766e6b;
    color: white;
    text-align: center;
    font-size: 1em;
}

.noti-key {
    padding-right: 0.9em;
    padding-top: 0.3em;
    font-size: 0.8em;
}

.noti-row {
    border-radius: 30px;
    padding-left: 1.2em;
    background: #534c46;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    color: white;
    margin-top: 0.6em;
    margin-right: 0.7em;
}

.paddingr15 {
    padding-right: 1.5em;
}

.policy-btn2-web {
    background: #c5281c;
    padding: 0.8em 3em;
    color: white;
    border-radius: 12px;
}

.margin-btm-negative2 {
    margin-bottom: -2em;
}

.margin-t3 {
    margin-top: 0.3em;
}

.padding-btm-1 {
    padding-bottom: 1.5em !important;
}

.padding-btm-2 {
    padding-bottom: 2em !important;
}

.policies-grey-bg {
    background: #f2f2f2;
    padding-left: 1em;
    padding-right: 1em;
}

.padding-t0 {
    padding-top: 0 !important;
}

.active-policies-pp {
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: var(--almost-black);
}

.color-black {
    color: #000000;
}

.mypolicies-icon-row {
    padding-left: 0.5em;
}

.mypolicy_title2 {
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 0.4px;
    color: rgba(9, 14, 21, 0.9);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.mypolicies_extrabtn {
    padding-right: 1em;
    padding-left: 1em;
    padding-bottom: 1.35em;
    padding-top: 0.7em;
    border-top: 1.5px solid #f2f2f2;
}

.policy-details-redbtn {
    padding-right: 1em;
    padding-left: 1em;
    padding-bottom: 3em;
}

.mypolicies-endDate {
    margin-bottom: 0 !important;
}

.margin-b5 {
    margin-bottom: 0.5em;
}

.font-almost1em {
    font-size: 0.88em !important;
}

.margin-t4em {
    margin-top: 0.25em;
}

.color-bg-grey {
    background: #f2f2f2;
}

.policydetails-row1 {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
    padding-bottom: 2em;
    background: white;
}

.mypolicies-icon {
    height: 3em;
    padding-top: 1.2em;
}

.margin-btmm18 {
    margin-bottom: -1.8em !important;
}

.font-size1em {
    font-size: 1em !important;
}

.font-size25px {
    font-size: 25px !important;
}

.padding-bottom02 {
    padding-bottom: 0.2em !important;
}

.mat-tab-group {
    display: flex;
    flex-direction: column;
    width: 100% !important;
}

.mat-tab-label {
    min-width: auto !important;
    width: 100% !important;
}

.background-white {
    background: white;
}

.margin-t14 {
    margin-top: 1.4em;
}

.paddin-btm1em {
    padding-bottom: 1em;
}

.transaction-details {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    color: var(--slate-grey);
    padding-left: 1em;
}

.transaction-details-row {
    padding-top: 1.1em;
    background: #f6f6f6;
    padding-bottom: 1em;
}

.padding-btm1em {
    padding-bottom: 1em;
}

.transaction-date-text {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: 0.2px;
    padding-top: 1em;
    padding-bottom: 1em;
    color: var(--slate-grey);
}

.transaction-date-dt {
    font-size: 1.2em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 0.4px;
    color: rgba(9, 14, 21, 0.9);
}

.coverage-c1 {
    padding-left: 1em;
    padding-top: 1em;
}

.coverage-t1 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: 0.2px;
    padding-bottom: 0.6em;
    color: var(--slate-grey);
}

.coverage-t2 {
    font-size: 1.08em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.73;
    letter-spacing: normal;
    color: rgba(9, 14, 21, 0.9);
    padding-bottom: 1em;
}

.transaction-date-row {
    padding-left: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #d4caca;
}

.vertical-line {
    border-left: 1px solid #d4caca;
    height: 4em;
}

.border-bottom-grey {
    border-bottom: 1px solid #d4caca;
}

.premium-details-t1 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: 0.2px;
    color: var(--slate-grey);
    padding-top: 1em;
    padding-bottom: 0.5em;
}

.premium-details-t2 {
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 0.4px;
    color: rgba(9, 14, 21, 0.9);
    padding-bottom: 1.2em;
    width: 100%;
    word-break: break-all;
}

.premium-policy {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    color: var(--slate-grey);
    padding-bottom: 1em;
}

.color-almostblack {
    color: var(--almost-black);
}

.mat-progress-bar {
    display: block;
    height: 0.35em !important;
    overflow: hidden;
    position: relative;
    transition: opacity 250ms linear;
    width: 100%;
    border-radius: 30px !important;
}

.mat-progress-bar-fill::after {
    background-color: #c5281c !important;
    border-radius: 30px !important;
}

.mat-progress-bar-buffer {
    background: #e4e8eb !important;
    border-radius: 30px !important;
}

.mat-progress-bar {
    border-radius: 30px !important;
}

.policy-amount {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    color: var(--battleship-grey);
}

.modify-content-txt {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--rusty-red);
}

.padding-t3 {
    padding-top: 0.3em;
}

.modify-content-row {
    background: white;
    border-top: 3px solid #c5281c;
}

.policy-details-btn {
    background: #7ed321;
    padding: 0.6em 1.2em 0.3em 1.2em;
    border-radius: 25px;
    color: white;
    font-size: 12px;
}

.mat-ink-bar {
    background-color: #c5281c !important;
    height: 0.2em !important;
}

.insurance-details-ct-text {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.4px;
    color: var(--slate-grey);
}

.insurance-details-ct-row {
    padding-top: 1em;
}

.insurance-details-r1 {
    padding: 1em;
}

.padding-t08 {
    padding-top: 0.8em;
}

.additiona-cov-t1 {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.33px;
    color: rgba(9, 14, 21, 0.9);
    padding-left: 0.6em;
}

.additiona-cov-icon {
    height: 1.79em;
}

.vertical-line-s {
    border-left: 1px solid #d4caca;
    height: 1em;
}

.wcoverage-c1 {
    padding-left: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
}

.wcoverage-rm {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.89;
    letter-spacing: normal;
    color: rgba(9, 14, 21, 0.9);
}

.wcoverage-amount {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.89;
    letter-spacing: normal;
    color: var(--slate-grey);
}

.margin-btmm1em {
    margin-bottom: -1em !important;
}

.padding-t7 {
    padding-top: 0.7em;
}

.document-download {
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: rgba(9, 14, 21, 0.9);
}

.rowHeight {
    min-height: 4em;
    border-bottom: 1px solid whitesmoke;
}

.iconColor {
    color: red;
    height: 2.5em !important;
}

.input-search .mat-form-field-underline {
    display: none !important;
}

.setheight agm-map {
    position: relative;
    height: calc(70vh - 4em - 64px);
}

.browserMap agm-map {
    position: relative;
    height: 42vh;
}

.map agm-map {
    position: relative;
    height: calc(90vh - 4em - 64px);
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: 0px 0px 0px -2px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.webmap agm-map {
    height: 31.5em;
}

.location agm-map {
    height: 25em;
}

// .locationSearchLabel mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-empty.mat-form-field-label{
//     padding:5px;
// }
@media only screen and (min-width: 0) {
    html {
        // font-size: unset !important;
    }
}

// .map-wrapper {
//   position: relative;
//   height: calc(100vh - 4em - 64px);
// }
// .sebm-google-map-container {
//     position: absolute;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
// }
.pointer {
    cursor: pointer;
}

.pointer:focus {
    outline: none;
}

.bottom-row {
    margin-top: 3%;
    margin-bottom: 3%;
}

.go-left {
    left: -20%;
    transform: translateX(-20%);
}

// .go-right{
//     right: 20%;
//     transform: translateX(20%)
// }
.list {
    transition: transform 300ms ease;
}

.cancel-button {
    background-color: white !important;
    color: black !important;
    border: 1px solid !important;
    margin: 16px !important;
    height: 56px;
}

.cancellation-row {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: white;
}

.margin-bottom-cancellation {
    margin-bottom: 200px;
}

.settings-row {
    padding-bottom: 0.3em;
    padding-top: 1em;
}

.buttontoggle button:focus {
    background-color: #d8d8d8 !important;
}

.mat-menu-panel.filterMap {
    height: 20em;
    margin-right: -1.375em;
    width: 16.6875em;
}

.filterWeb {
    min-height: 3.25em;
    border-bottom: 1px solid whitesmoke;
    font-size: 14px;
}

.mat-expansion-panel-content.filtercontent {
    margin-bottom: 2px;
    height: 58px;
}

.filterMap::-webkit-scrollbar {
    display: none;
}

.hideHeader {
    display: none;
}

.filterWebButton {
    width: 160px;
    height: 56px;
    font-size: 17px !important;
    background-color: #c5281c !important;
    color: #ffffff !important;
    margin: 20px !important;
}

.my-profile-row {
    padding: 3%;
}

.myprofile-icon {
    height: 2em;
    width: 2em;
}

.paymentstatusbutton {
    font-size: 1.0625rem !important;
    height: 56px;
    width: 325px;
    background-color: #c5281c !important;
    color: #ffffff !important;
}

.paymentstatuscancelbutton {
    background-color: white !important;
    color: black !important;
    border: 1px solid !important;
    margin: 16px !important;
    height: 56px;
    width: 325px;
}

.travelPopupWebbutton {
    font-size: 1.0625rem !important;
    height: 56px;
    width: 160px;
    color: #ffffff !important;
}

.travelpopupButton {
    font-size: 1.0625rem !important;
    height: 56px;
    width: 298px;
    color: #ffffff !important;
}

.columnAlign {
    margin-top: 3em;
}

.travelHeaderWeb {
    height: 4.5em;
    background-color: var(--very-light-pink);
}

.textgrow {
    flex-grow: 1; //fill the space
    width: 15px;
    content: "";
    display: block;
}

.font-14 {
    font-size: 14px;
}

.font-12 {
    font-size: 12px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-33 {
    font-size: 33px !important;
}

.buttoncolor.mat-button {
    background: #c5281c !important;
    color: #ffffff !important;
    border-radius: 0px;
}

.cancelbutton.mat-button {
    border-radius: 0px;
    border: 1px solid #707070;
    color: #000000;
}

.padding10 {
    padding: 10px;
}

.travelLabel {
    font-size: 14px;
    color: var(--slate-grey);
}

.locatorLabel {
    font-size: 13px;
    color: var(--slate-grey);
}

.travelPlanRow {
    width: 295px;
    height: 410px;
    border-radius: 8px;
    background-color: var(--rusty-red);
}

.travelFont {
    font-size: 14px;
    color: white;
}

.width100 {
    width: 100%;
}

.padding8 {
    padding: 8px;
}

.padding1 {
    padding: 1em;
}

.quoteButton {
    width: 269.7px;
    height: 56px;
    background-color: var(--white);
    color: #c5281c !important;
}

.margin-top {
    margin-top: 0.4em;
}

.locatorrow {
    min-height: 10em;
    max-height: 13em;
}

.listheader {
    color: #090e15;
    font-size: 17px;
}

.truncate-overflow {
    position: relative;
    max-height: calc(1.4rem * 3);
    overflow: hidden;
}

.truncate-overflow::before {
    position: absolute;
    content: "...";
    bottom: 0;
    right: 0;
}

.truncate-overflow::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    background: white;
}

//Registration page Web
.reg-bg {
    background: #e5e5e5;
}

.padding-t8 {
    padding-top: 8em;
}

.padding-b4 {
    padding-bottom: 4em;
}

.reg-web-card {
    background: white;
    padding: 3em 0em;
}

.my-tooltip {
    white-space: pre-line;
}

.iosfix {
    display: block !important;
}

.full-width .mat-dialog-container {
    max-width: 100vw !important;
}

.agree-button {
    color: var(--rusty-red) !important;
    border: 1px solid !important;
    background: white !important;
}

.submit-button {
    background: #c5281c !important;
    height: 3.5em;
    color: #ffffff !important;
}

.cancl-button {
    background: #ffffff !important;
    height: 3.5em;
    border: solid 2px var(--slate-grey) !important;
}

.border.mat-button {
    border-radius: 0px !important;
}

.custom-dialog-container .mat-dialog-container {
    padding: 1em !important;
    max-width: none !important;
}

[onClick] {
    cursor: pointer !important;
}

.cursor-pointer {
    cursor: pointer;
}

.pdf {
    .pdfViewer .page {
        border: none !important;
    }

    .pdfViewer .canvasWrapper {
        border: 6px solid lightgrey;
    }

    .splitToolbarButton {
        margin: 0px !important;
    }

    .toolbarLabel {
        display: none;
    }
}

.full-width-dialog {
    .mat-dialog-container {
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden;
        overflow-y: auto;
        height: 100vh;
        padding: 0px !important;
        box-shadow: none !important;
    }
}

.g-transparent-backdrop {
    background: rgba(255, 255, 255, 0);
    opacity: 0.2;
}

.removemargin.mat-toolbar-single-row {
    padding: 0px !important;
}

.motor-header-mdp.mat-toolbar-single-row {
    white-space: normal !important;
}

.spinner,
.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #c5281c !important;
}

.uploader-status ::ng-deep .mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #ffffff;
}

.auto-assistDialog {
    .mat-dialog-container {
        top: 0 !important;
        padding: 1em !important;
        background: #f1644a;
        color: white;
    }
}

.mat-slide-toggle.mat-disabled {
    opacity: initial !important;
}

.dot {
    margin-left: 2px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 4px 4px;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border-radius: 50%;
}

.dot-active {
    margin-left: 2px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 4px 4px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border-radius: 50%;
    background-color: white !important;
}

.viewer-pdf {
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 90vh;
}

.mat-raised-button.save-quote-web {
    height: 3.5em;
    color: #c5281c !important;
    background-color: var(--white) !important;
}

.mat-icon-button.iconbutton {
    background: none !important;
}

.iconbutton button:focus {
    background: none !important;
}

.save-quote-summary {
    height: 3.5em;
    background-color: #d23a29 !important;
    border: 2px solid #ffffff !important;
    color: #ffffff !important;
}

.font-icons {
    font-size: 1.8rem;
}

.get-quote {
    overflow: auto;
    height: 12em;
}

.header-web {
    width: 135px;
    height: 31px;
}

.card-padding {
    padding-top: 2em;
    padding-bottom: 2em;
}

.motor-header {
    text-align: center;
    font-size: 17px;
    color: #ffffff;
}

.sub-heading {
    background: #f6f6f6;
    color: #6f7072;
    font-size: 14px;
}

.battleship-grey {
    color: #6f7072;
}

.motor-plan-card {
    border: 0.958244px solid #c5281c;
    border-radius: 9.58244px;
}

.plan-card-title {
    background: #a71a0f;
    font-size: 15px;
    text-align: center;
    color: #ffffff;
    border-radius: 9.58244px 9.58244px 0px 0px;
}

.benefits-motor {
    font-size: 14px;
    color: #c5281c;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 5px;
    border-radius: 10px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    height: 100%;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    background-color: #c5281c !important;
}

.mat-accent .mat-slider-thumb {
    height: 18px;
    width: 18px;
    background-color: #c5281c !important;
    border: 2px solid #c5281c;
    bottom: -10px;
    right: -12px;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: #c5281c !important;
    border: none;
}

.radio-button-label {
    .mat-radio-label-content {
        font-size: 14px !important;
        color: #333333 !important;
    }
}

.padding-10 {
    padding: 16px !important;
}

.MDP-card {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 9.58244px;
}

.selected-card {
    background: #C5281C;
    color: white;
}

.mdp-header-padding {
    padding-left: 1em;
    padding-top: 1em;
    padding-bottom: 0.5em;
}

.motor-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #c5281c !important;
    border: 1px solid #ffffff !important;
}

.align-image {
    display: flex;
    place-content: center !important;
    align-items: center;
}

.policycard {
    width: 10em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.motor-panel.mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none;
    border-bottom: 1px solid #cccccc;
}

.padding-expansion.mat-expansion-panel-header {
    padding: 0 8px;
}

.motor-panel-header.mat-expansion-panel-header {
    background: #ebebeb !important;
}

.prevent-click {
    pointer-events: none;
    opacity: 0.5;
}

.authorize-click {
    pointer-events: auto;
}

.rotate-icon {
    transform: rotate(180deg);
    transition: 0.5s;
}

.rotate-icon-0 {
    transform: rotate(0deg);
    transition: 0.5s;
}

.border-bottom-grey {
    border-bottom: 1px solid #cccccc;
}

.no-line {
    .mat-form-field-underline {
        display: none;
    }

    text-align: center;
}

.field-width {
    width: 90%;
}

.right-border {
    border-right: 1px solid #cccccc;
}

.web-sidenav {
    position: fixed !important;
    margin-top: 56px;
    width: 311px;
    background-color: #c5281c !important;
}

.exp-panel-header {
    background-color: #f6f6f6 !important;
}

.box-shadow-none {
    mat-expansion-panel:not([class*="mat-elevation-z"]) {
        box-shadow: none !important;
    }
}


/*
* vertical tabs css
*/

.vertical-tabs {
    .mat-tab-group {
        flex-direction: row !important;
    }

    .mat-tab-header {
        border-bottom: none;
        padding: 24px 0 24px 24px;
    }

    .mat-tab-header-pagination {
        display: none !important;
    }

    .mat-tab-labels {
        flex-direction: column;
        justify-content: flex-start;
        background: white;
    }

    .mat-ink-bar {
        width: 0px !important;
    }

    .mat-tab-body-wrapper {
        flex: 1 1 auto;
        padding: 24px;
    }

    .mat-tab-label-active {
        border-left: 4px solid rgb(197, 40, 28) !important;
        background-color: #f1f1f1;
    }

    .transaction-date-row {
        background: white;
    }
}

.exp-indicator {
    .mat-expansion-indicator {
        display: none;
    }
}

.expansion-icon.mat-icon {
    font-size: 24px;
    display: flex;
    align-items: center !important;
    place-content: center !important;
}

.edit-policy-icons {
    color: #c5281c;
}

.payment-panel-class {
    .mat-dialog-container {
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden;
        overflow-y: auto;
        height: 100vh;
        padding: 0px !important;
        box-shadow: none !important;
        background-color: rgba(0, 0, 0, 0.8);
        background: -webkit-radial-gradient(50% 50%, ellipse closest-corner, rgba(0, 0, 0, 0.6) 1%, rgba(0, 0, 0, 0.8) 100%);
    }
}

.sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 999;
}

.button-disabled.mat-button {
    background-color: #cccccc !important;
    color: #5c596d;
    pointer-events: none;
}

.locator-web-card {
    height: 42em;
    overflow-y: auto;
}

.motorplans-panel .mat-expansion-panel-body {
    place-content: center;
    align-items: center;
    display: flex;
}

.pol-doc {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.002em;
    color: #000000;
}

.pol-doc-name {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.002em;
    color: #6f7072;
}

.margin-lr {
    margin-left: 1em;
    margin-right: 1em;
}

.gtQuotebckImage {
    background-image: url("./assets/lllustration/BG.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 50vh);
}

.gtQuoteWebBckImage {
    height: calc(100vh - 57vh);
    background-image: url("./assets/lllustration/car02.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.font-33 {
    font-size: 33px;
}

.quote-autoComplete.mat-form-field-appearance-legacy .mat-form-field-underline,
.quote-autoComplete.mat-form-field-appearance-legacy .mat-form-field-ripple,
.quote-autoComplete.mat-form-field-appearance-legacy.mat-focused .mat-form-field-underline,
.quote-autoComplete.mat-form-field-appearance-legacy.mat-focused .mat-form-field-ripple {
    background-color: white !important;
}

.quote-autoComplete.mat-form-field-appearance-legacy .mat-form-field-label,
.quote-autoComplete.mat-form-field-appearance-legacy.mat-focused .mat-form-field-label {
    color: white !important;
}

.quote-autoComplete.mat-form-field-appearance-legacy .mat-input-element {
    caret-color: white !important;
    color: white !important;
}

.quote-autoComplete.mat-form-field {
    .mat-input-element {
        color: white !important;
    }
}

.quote-autoComplete {
    input::placeholder {
        color: white !important;
    }
}

.field-color {
    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: rgba(247, 13, 13, 0.42) !important;
    }
}

.font-color-red {
    color: red;
}

.font-color-black {
    color: black;
}

.notification-panel .mat-expansion-panel-body {
    background: #f6f6f6;
}

.notification-header.mat-expansion-panel-header {
    padding: 0 14px;
}

.activeClass {
    border-bottom: 0.339rem solid white;
    padding: 9.5px 16px;
}

.not-activeClass {
    border-bottom: 0 solid white;
    padding: 0px;
}

.routeActive:focus {
    outline: 0;
}

.news-card-image {
    height: 100%;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.news-card-mobile {
    height: 15em;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.card-height {
    height: 22em;
}

.products-web {
    border-radius: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 22em;
}

.white-color {
    color: #ffffff;
    outline: 0;
}

.error {

    // border-left: 14px solid #c5281c;
    .mat-simple-snackbar-action {
        color: #ff9f00 !important;
    }
}

.snacky {
    background: rgb(36, 36, 36);
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    padding: 14px;
    position: relative;
    bottom: calc(100vh - 90vh);
}

.helpcenter {
    color: rgba(0, 0, 0, 0.9);
    font-size: 21px;
    padding: 4%;
}

.agentWebImage {
    background-image: url("assets/Web/Bitmap.png");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: calc(100vh - 65vh) !important;
}

.disable-field {
    .mat-form-field-underline {
        display: none;
    }

    pointer-events: none;
}

.policy-active {
    color: white;
    background-color: #7ed321;
}

.policy-expire {
    color: white;
    background-color: #ff9f00;
}

.policy-cancel {
    color: #6e6f71;
    border: #d8d8d8 1px solid;
}

.policy-pending {
    color: white;
    background-color: #f1644a;
}

.prenotify {
    max-width: 95vw !important;

    .mat-dialog-container {
        padding: 1em !important;
        background: #c5281c;
        border-radius: 12px;
    }

    .mat-dialog-content {
        padding: 0 !important;
        margin: 0 !important;
        padding-right: 0.3em !important;
    }

    .mat-dialog-content::-webkit-scrollbar {
        width: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    .mat-dialog-content::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: darkgrey;
        border-radius: 12px;
    }

    .mat-dialog-content::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
        border-radius: 12px;
    }
}

.prenotify-mobileimg {
    height: 6.5rem;
    border-radius: 0.4rem;
}

.prenotify-webimg {
    cursor: pointer;
    height: 9.5rem;
    border-radius: 0.4rem;
}

b,
strong {
    font-weight: 900 !important;
}

.contentTitle4 {
    font-size: 1.28rem;
}

.ck-h2 {
    font-size: 2.5em !important;
}

.ck-h3 {
    font-size: 2em !important;
}

.ck-h4 {
    font-size: 1.3em !important;
}

.ck-content p {
    display: block !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
}

.ck-content .text-tiny {
    font-size: .7em !important;
}


/* ckeditor5-font/theme/fontsize.css */

.ck-content .text-small {
    font-size: .85em !important;
}


/* ckeditor5-font/theme/fontsize.css */

.ck-content .text-big {
    font-size: 1.4em !important;
}


/* ckeditor5-font/theme/fontsize.css */

.ck-content .text-huge {
    font-size: 1.8em !important;
}

.black-dot {
    border: 4px solid;
    border-radius: 50%;
    height: 4px;
    width: 4px;
    margin-top: 4px;
}

.pickup-header {
    font-size: 20px;
    font-weight: 600;
    // text-align: center;
}

.justify-text {
    text-align: justify;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 420px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.center-text {
    text-align: center;
}

a {
    cursor: pointer;
}

.delete-success {
    background-color: #c5281c !important;
    padding: 1em !important;
    max-width: none !important;

    .mat-dialog-container {
        background-color: #c5281c !important;
        box-shadow: none;
    }
}

.displayflex {
    display: flex !important;
}

.claims-select-category {
    font-size: 20px;
    font-weight: 500;
    background-color: #f6f6f6;
    min-height: 48px;
    color: #6e6f71;
    padding: 15px;
    min-height: 57px;
}

.claim-category {
    padding: 15px;
    font-size: 19px;
    border-bottom: 1px solid #B0B2B3;
    font-weight: 500;

    .mat-icon {
        height: 35px;
        width: 35px;
        font-size: 35px;
        color: #6e6f71;
    }
}

.claims-toolbar {
    color: white;

    .mat-icon {
        height: 30px;
        width: 30px;
        font-size: 30px;
    }
}

.bank-details-form {
    padding: 20px 15px;

    .label-name {
        font-size: 16px;
        font-weight: 500;
    }

    .mat-form-field-infix {
        font-size: 18px;
        font-weight: 500;
        border-top: 2px solid transparent;
        padding-top: 2px !important;
    }
}

.panel-non-panel {
    .mat-radio-label-content {
        font-size: 16px;
        font-weight: 500;
        color: black;
        padding-left: 12px;
    }
}

.rm-prefix {
    font-size: 14px;
    font-weight: 500;
    color: black;
    margin-right: 10px;
}

.padding15 {
    padding: 15px;
}

.card-background {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
        linear-gradient(0deg, #F2F2F2, #F2F2F2) !important;
}

.width50 {
    width: 50%;
}